<template>
  <div class="box_1" ref="home_box">
    <div class="header">
      <div class="text cursor" @click="goToPage('/bigscreen/menuPage')">兽医120呼叫中心</div> 
    </div>

    <div class="content_box full flex_row_around">
      <!-- <div class="h_60 absolute left_center top_10 mon_text lh_40 color_fff fs_24">
        ᠮᠠᠯ᠎ᠤᠨ ᠡᠮᠴᠢ 120 ᠳᠠᠭᠤᠳᠠᠯᠲᠠ᠎ᠶᠢᠨ ᠲᠥᠪ
      </div> -->

      <!-- box2 -->
      <div class="center_box w_50_p">
        <div class="map_box w_full h_75_p">
          <map-com class="map" @emitData="emitData" />
        </div>

        <el-dialog
          title="呼叫"
          :visible.sync="showIframe"
          width="60%"
          class="h_full"
          :opened="openDialog()"
        >
          <div class="full h_300">
            <!-- <iframe id="ifm" class="full" src="https://dsj.dayaar.com.cn/pro/nmbigdata/cattlecf/chatroom" frameborder="0"></iframe> -->
            <iframe id="ifm" class="full" :src="callUrl" frameborder="0"></iframe>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="showIframe = false">取 消</el-button>
            <el-button type="primary" @click="showIframe = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>

      <!-- box3 -->
      <div class="right_box w_35_p">
        <div class="user_list_box w_full h_46_p cursor">
          <div class="title"><span class="ml_40" @click="showIframe=true">呼叫信息</span></div>

          <div class="hu_jiao_box ph_10 border_box w_full h_90_p relative" v-if="userInfo.name">
            <!-- 用户信息、病情 -->
            <div class="user_info w_full flex_row_between">
              <div class="w_50_p">
                <span class="color_green" @click="goToFangYi(userInfo.name)">姓名：</span>
                <span class="color_fff">{{ userInfo.name }}</span>
              </div>
              <div class="w_50_p">
                <span class="color_green">电话：</span>
                <span class="color_fff">{{ userInfo.phone }} 
              </span>
              </div>
            </div>
            <div class="user_addr w_full">
              <span class="color_green">地址：</span>
              <span class="color_fff">{{ userInfo.addr }}</span>
            </div>
            <div class="cattle_state w_full">
              <span class="color_green" v-if="cattleState && cattleState.length">病情：</span>
              <span class="color_fff" v-for="(val, i) in cattleState" :key="i">
                {{ val }}
                <span v-if="i < cattleState.length - 1">，</span>
              </span>
            </div>

            <!-- 视频 、 图片 -->
            <div class="w_full h_50_p">
              <div class="w_25_p h_full p_5 float_left" v-for="(item, index) in fileList" :key="index">
                <!-- <img class="full" v-if="item.substr(-3) == 'jpg'" :src="baseUrl + item" alt="">
                <video class="full bgc_000" v-if="item.substr(-3) == 'mp4'" :src="baseUrl + item" autoplay controls loop></video> -->
                

                <img class="full" v-if="item.substr(-3) == 'jpg'" :src="fileUrl + item" alt="">
                <video class="full bgc_000" v-if="item.substr(-3) == 'mp4'" :src="fileUrl + item" autoplay controls loop></video>
              </div>
            </div>

            <span class="hu_jiao_btn" v-if="userInfo.name" @click="showIframe=true">呼叫</span> 
          </div>
        </div>

        <div class="user_list_box w_full h_27_p cursor" v-show="false">
          <div class="title"><span>兽医列表</span></div>

          <el-carousel 
            class="swiper color_fff" 
            indicator-position="none" 
            direction="vertical" 
            :autoplay="true"
          >
            <el-carousel-item 
              class="shou_yi_item flex_row_around" 
              v-for="(item, index) in userList" :key="index"
            >
              <div class="avatar_box w_30_p">
                <img :src="item.avatar" alt="">
              </div>
              
              <div class="user_info_box w_60_p">
                <div class="item">
                  <span class="color_green">姓名：</span> {{ item.name || '' }}
                </div>
                <!-- <div class="item mb_2">
                  年龄：{{ item.age || '37' }}
                </div> -->
                <!-- <div class="item mb_2">
                  住址：{{ item.addr || '' }}
                </div> -->
                <div class="item">
                  <span class="color_green">电话：</span> {{ item.phone|| '' }}
                </div>
                <div class="item">
                  <span class="color_green">工龄：</span> {{ item.workAge || '' }}
                </div>
                <div class="item flex_row_between">
                  <span class="color_green">防疫质量：</span> 
                    <div class="star w_15 h_15" v-for="(star, i) in item.star" :key="i">
                      <img class="full" :src="starIcon" alt="">
                    </div>
                </div>
                <div class="item">
                  <span class="color_green">距离：</span>10 km
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>  
        </div>

        <div class="user_list_box w_full h_27_p cursor" v-show="false">
          <div class="title"><span>就诊进行中</span></div>
          <el-carousel 
            class="swiper color_fff" 
            indicator-position="none" 
            direction="vertical" 
            :autoplay="true"
            interval="7000"
          >
            <el-carousel-item 
              class="jiu_zhen_item flex_row_around" 
              v-for="(item, index) in userList" :key="index"
            >
              <div class="avatar_box w_30_p">
                <img src="../../../../assets/images/niu-jiu-zhen/img1.jpg" alt="">
              </div>
              
              <div class="user_info_box w_60_p">
                <div class="item">
                  <span class="color_green">位置：</span> {{ item.addr || '大板镇大冷村' }}
                </div>
                <div class="item">
                  <span class="color_green"> 兽医：</span>{{ item.name || '那木日' }}
                </div>
                <div class="item">
                  <span class="color_green">电话：</span> {{ item.phone || '13877318746' }}
                </div>
                <div class="item">
                  <span class="color_green">距离：</span>10 km
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>  
        </div>
      </div>  

      <!-- websoket 链接状态 -->
      <span class="connect_text color_orange" v-if="connectType == 0">webSoket 尝试连接中...</span>
      <span class="connect_text color_red" v-if="connectType == 1">webSoket 连接失败！请刷新页面。</span>
      <span class="connect_text color_green" v-if="showText && connectType == 2">webSoket 已连接成功！</span>
    </div>
  </div>
</template>

<script>
  import mapCom from '../../../../components/map/fang-yi-map.vue'
  import { baseURL } from '@/const'

  export default {
    name: 'Home',
    components: {
      mapCom
    },
    data() {
      return {
        showText: true,
        connectType: '',
        showIframe: false,
        callUrl: baseURL + '/nmbigdata/cattlecf/chatroom',
        // baseUrl: 'http://dsj.dayaar.com.cn/pro/profile/',
        fileUrl: baseURL + '/profile/',
        userInfo: {
          name: '',
          phone: '',
          addr: ''
        },
        cattleState: [],
        fileList: [],
        fangYiYuan: [],

        starIcon: require('../../../../assets/images/ba-lin-you-qi/star.svg'),
        
        // reloadFrame: true
      }
    },
    created() {
      
    },
    mounted () {
      this.connectType = sessionStorage.getItem('connect')

      // if (this.connectType == 2) {
      //   location.reload()
      // }

      // setTimeout(() => { this.showText = false }, 5000)
    },
    destroyed() {
    },
    methods: {
      // 跳转页面 
      goToPage(val) {
        this.$router.push({
          path: val
        })
      },

      goToFangYi(val) {
        // if (this.fangYiYuan.name) {

        // }

        // this.$router.push({
        //   path: '/bigscreen/gongZuoTongJi' + val
        // })

      },

      // 获取 map.vue 发送的 数据
      emitData(val) {
        this.userInfo = val.userInfo
        this.cattleState = val.cattleState || []
        this.fileList = val.fileList
        this.fangYiYuan = val.fangYiYuan || []
      },

      // 点击 呼叫 按钮
      openDialog() {
        console.log(document.getElementById('ifm'));
   
        this.$nextTick(()=>{
          var el = document.getElementById('ifm')
          el.contentWindow.location.reload(true);
        })
        // this.ifmSrc = 'https://hxyc.dayaar.com.cn/pro/nmbigdata/cattlecf/chatroom'
      },


    }
  }
</script>

<style lang="less" scoped>
  .hu_jiao_box { position: relative; }
  .hu_jiao_btn { 
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: orange;
    color: #fff;
    position: absolute;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 1px 10px 5px #eee;
  }

.swiper { 
  width: 100%;
  height: 88% !important;
  padding: 5px;
  box-sizing: border-box;
  // border: 1px solid #f00;
}

.shou_yi_item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 0;

  .avatar_box {
    img { width: 100%; height: 100%; border-radius: 10px; }
  }

  .user_info_box {
    width: 50%;
    height: 100%;
    position: relative;
    top: -2px;
  }
}

.jiu_zhen_item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;
  box-sizing: border-box;
  padding-top: 0;

  .avatar_box {
    img { width: 100%; height: 100%; border-radius: 10px; }
  }

  .user_info_box {
    position: relative;
    top: -5px;
  }
}
  .box_1 {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    .content_box {
      height: calc(100% - 40px);
      padding: 0 10px;
      position: absolute;
      top: 30px;
      left: 0;

      // =================== box2 ====================
      .center_box {
        position: relative;
        .map_box {
          width: 230%; height: 100%;
          margin-left: -50%;
          z-index: -1;
          .map { width: 100%; height: 75%; }
        }
      }
      .right_box {
        z-index: 99;
        .user_list_box {
          margin: 10px 0;

          .title { 
            width: 100%;
            height: 12%;
            position: relative;
            // border: 1px solid #f00;

            span {
              font-weight: bold; 
              color: #82fefe; 
              cursor: pointer; 
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      .connect_text {
        position: absolute;
        bottom: 0px;
        right: 30px;
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .title { 
      font-size: 10px !important;
    }
  }
</style>

<style>
.el-carousel__container { height: 100% !important; }
</style>
